import axios from '@/common/axios'
import qs from 'qs'

export function addDisinfectantCollection (data) {
  return axios({
    method: 'post',
    url: '/universal/disinfectantCollection/add',
    data: qs.stringify(data)
  })
}

export function deleteDisinfectantCollection (id) {
  return axios({
    method: 'delete',
    url: '/universal/disinfectantCollection/delete/' + id
  })
}

export function updateDisinfectantCollection (data) {
  return axios({
    method: 'put',
    url: '/universal/disinfectantCollection/update',
    data: qs.stringify(data)
  })
}

export function selectDisinfectantCollectionInfo (id) {
  return axios({
    method: 'get',
    url: '/universal/disinfectantCollection/info/' + id
  })
}

export function selectDisinfectantCollectionList (query) {
  return axios({
    method: 'get',
    url: '/universal/disinfectantCollection/list',
    params: query
  })
}

export function reviewDisinfectantCollection (data) {
  return axios({
    method: 'put',
    url: '/universal/disinfectantCollection/review',
    data: qs.stringify(data)
  })
}

export function confirmDisinfectantCollection (data) {
  return axios({
    method: 'put',
    url: '/universal/disinfectantCollection/confirm',
    data: qs.stringify(data)
  })
}
