<template>
  <div id="disinfectantCollection">
    <el-dialog
      :title="disinfectantCollectionFormTitle"
      width="680px"
      :visible.sync="disinfectantCollectionDialogVisible"
      :close-on-click-modal="false"
      @close="disinfectantCollectionDialogClose"
    >
      <el-form
        ref="disinfectantCollectionFormRef"
        :model="disinfectantCollectionForm"
        :rules="disinfectantCollectionFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="编号" prop="serialNumber">
              <el-input
                v-model="disinfectantCollectionForm.serialNumber"
                placeholder="请输入编号"
                clearable
                :disabled="disinfectantCollectionFormTitle !== '新增消毒剂/洗涤剂领用申请表'
                  && disinfectantCollectionFormTitle !== '修改消毒剂/洗涤剂领用申请表'
                  && disinfectantCollectionFormTitle !== '消毒剂/洗涤剂领用申请表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请部门" prop="applyDept">
              <el-input
                v-model="disinfectantCollectionForm.applyDept"
                placeholder="请输入申请部门"
                clearable
                :disabled="disinfectantCollectionFormTitle !== '新增消毒剂/洗涤剂领用申请表'
                  && disinfectantCollectionFormTitle !== '修改消毒剂/洗涤剂领用申请表'
                  && disinfectantCollectionFormTitle !== '消毒剂/洗涤剂领用申请表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请日期" prop="applyDate">
              <el-date-picker
                v-model="disinfectantCollectionForm.applyDate"
                placeholder="请选择申请日期"
                value-format="yyyy-MM-dd"
                :disabled="disinfectantCollectionFormTitle !== '新增消毒剂/洗涤剂领用申请表'
                  && disinfectantCollectionFormTitle !== '修改消毒剂/洗涤剂领用申请表'
                  && disinfectantCollectionFormTitle !== '消毒剂/洗涤剂领用申请表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="名称" prop="name">
              <el-input
                v-model="disinfectantCollectionForm.name"
                placeholder="请输入名称"
                clearable
                :disabled="disinfectantCollectionFormTitle !== '新增消毒剂/洗涤剂领用申请表'
                  && disinfectantCollectionFormTitle !== '修改消毒剂/洗涤剂领用申请表'
                  && disinfectantCollectionFormTitle !== '消毒剂/洗涤剂领用申请表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="领用数量" prop="quantity">
              <el-input
                v-model="disinfectantCollectionForm.quantity"
                placeholder="请输入领用数量"
                clearable
                :disabled="disinfectantCollectionFormTitle !== '新增消毒剂/洗涤剂领用申请表'
                  && disinfectantCollectionFormTitle !== '修改消毒剂/洗涤剂领用申请表'
                  && disinfectantCollectionFormTitle !== '消毒剂/洗涤剂领用申请表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="disinfectantCollectionForm.remarks"
                placeholder="请输入备注"
                clearable
                :disabled="disinfectantCollectionFormTitle !== '新增消毒剂/洗涤剂领用申请表'
                  && disinfectantCollectionFormTitle !== '修改消毒剂/洗涤剂领用申请表'
                  && disinfectantCollectionFormTitle !== '消毒剂/洗涤剂领用申请表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核结果" prop="reviewerResult">
              <el-radio-group
                v-model="disinfectantCollectionForm.reviewerResult"
                :disabled="disinfectantCollectionFormTitle !== '审核消毒剂/洗涤剂领用申请表'
                  && disinfectantCollectionFormTitle !== '消毒剂/洗涤剂领用申请表详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认结果" prop="confirmerResult">
              <el-radio-group
                v-model="disinfectantCollectionForm.confirmerResult"
                :disabled="disinfectantCollectionFormTitle !== '确认消毒剂/洗涤剂领用申请表'
                  && disinfectantCollectionFormTitle !== '消毒剂/洗涤剂领用申请表详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="disinfectantCollectionDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="disinfectantCollectionFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="编号">
        <el-input v-model="searchForm.serialNumber" placeholder="请输入编号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['TY_DISINFECTANT_COLLECTION_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="disinfectantCollectionPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column prop="serialNumber" label="编号" />
      <el-table-column prop="applyDept" label="申请部门" />
      <el-table-column label="申请日期">
        <template slot-scope="scope">
          <span v-if="scope.row.applyDate">{{ scope.row.applyDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="quantity" label="领用数量" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="editor" label="编制人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.editorDate">{{ scope.row.editorDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="审核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerDate">{{ scope.row.reviewerDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerResult === 1">通过</span>
          <span v-if="scope.row.reviewerResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column prop="confirmer" label="确认人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.confirmerDate">{{ scope.row.confirmerDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="确认结果">
        <template slot-scope="scope">
          <span v-if="scope.row.confirmerResult === 1">通过</span>
          <span v-if="scope.row.confirmerResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['TY_DISINFECTANT_COLLECTION_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['TY_DISINFECTANT_COLLECTION_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['TY_DISINFECTANT_COLLECTION_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            审核
          </el-button>
          <el-button
            v-if="checkPermission(['TY_DISINFECTANT_COLLECTION_CONFIRM']) && scope.row.status === 2"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleConfirm(scope.$index, scope.row)"
          >
            确认
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="disinfectantCollectionPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addDisinfectantCollection,
  deleteDisinfectantCollection,
  updateDisinfectantCollection,
  selectDisinfectantCollectionInfo,
  selectDisinfectantCollectionList,
  reviewDisinfectantCollection,
  confirmDisinfectantCollection
} from '@/api/universal/disinfectantCollection'

export default {
  data () {
    return {
      disinfectantCollectionDialogVisible: false,
      disinfectantCollectionFormTitle: '',
      disinfectantCollectionForm: {
        id: '',
        serialNumber: '',
        applyDept: '',
        applyDate: '',
        name: '',
        quantity: '',
        remarks: '',
        reviewerResult: '',
        confirmerResult: '',
        taskId: ''
      },
      disinfectantCollectionFormRules: {
        serialNumber: [{ required: true, message: '编号不能为空', trigger: ['blur', 'change']}]
      },
      disinfectantCollectionPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        serialNumber: ''
      }
    }
  },
  created () {
    selectDisinfectantCollectionList(this.searchForm).then(res => {
      this.disinfectantCollectionPage = res
    })
  },
  methods: {
    disinfectantCollectionDialogClose () {
      this.$refs.disinfectantCollectionFormRef.resetFields()
    },
    disinfectantCollectionFormSubmit () {
      if (this.disinfectantCollectionFormTitle === '消毒剂/洗涤剂领用申请表详情') {
        this.disinfectantCollectionDialogVisible = false
        return
      }
      this.$refs.disinfectantCollectionFormRef.validate(async valid => {
        if (valid) {
          if (this.disinfectantCollectionFormTitle === '新增消毒剂/洗涤剂领用申请表') {
            this.disinfectantCollectionForm.id = ''
            await addDisinfectantCollection(this.disinfectantCollectionForm)
          } else if (this.disinfectantCollectionFormTitle === '修改消毒剂/洗涤剂领用申请表') {
            await updateDisinfectantCollection(this.disinfectantCollectionForm)
          } else if (this.disinfectantCollectionFormTitle === '审核消毒剂/洗涤剂领用申请表') {
            await reviewDisinfectantCollection(this.disinfectantCollectionForm)
          } else if (this.disinfectantCollectionFormTitle === '确认消毒剂/洗涤剂领用申请表') {
            await confirmDisinfectantCollection(this.disinfectantCollectionForm)
          }
          this.disinfectantCollectionPage = await selectDisinfectantCollectionList(this.searchForm)
          this.disinfectantCollectionDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.disinfectantCollectionFormTitle = '新增消毒剂/洗涤剂领用申请表'
      this.disinfectantCollectionDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteDisinfectantCollection(row.id)
        if (this.disinfectantCollectionPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.disinfectantCollectionPage = await selectDisinfectantCollectionList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.disinfectantCollectionFormTitle = '修改消毒剂/洗涤剂领用申请表'
      this.disinfectantCollectionDialogVisible = true
      this.selectDisinfectantCollectionInfoById(row.id)
    },
    handleReview (index, row) {
      this.disinfectantCollectionFormTitle = '审核消毒剂/洗涤剂领用申请表'
      this.disinfectantCollectionDialogVisible = true
      this.selectDisinfectantCollectionInfoById(row.id)
    },
    handleConfirm (index, row) {
      this.disinfectantCollectionFormTitle = '确认消毒剂/洗涤剂领用申请表'
      this.disinfectantCollectionDialogVisible = true
      this.selectDisinfectantCollectionInfoById(row.id)
    },
    handleInfo (index, row) {
      this.disinfectantCollectionFormTitle = '消毒剂/洗涤剂领用申请表详情'
      this.disinfectantCollectionDialogVisible = true
      this.selectDisinfectantCollectionInfoById(row.id)
    },
    selectDisinfectantCollectionInfoById (id) {
      selectDisinfectantCollectionInfo(id).then(res => {
        this.disinfectantCollectionForm.id = res.id
        this.disinfectantCollectionForm.serialNumber = res.serialNumber
        this.disinfectantCollectionForm.applyDept = res.applyDept
        this.disinfectantCollectionForm.applyDate = res.applyDate
        this.disinfectantCollectionForm.name = res.name
        this.disinfectantCollectionForm.quantity = res.quantity
        this.disinfectantCollectionForm.remarks = res.remarks
        this.disinfectantCollectionForm.reviewerResult = res.reviewerResult
        this.disinfectantCollectionForm.confirmerResult = res.confirmerResult
        this.disinfectantCollectionForm.taskId = res.taskId
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectDisinfectantCollectionList(this.searchForm).then(res => {
        this.disinfectantCollectionPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectDisinfectantCollectionList(this.searchForm).then(res => {
        this.disinfectantCollectionPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectDisinfectantCollectionList(this.searchForm).then(res => {
        this.disinfectantCollectionPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['TY_DISINFECTANT_COLLECTION_REVIEW']) && row.status === 1) {
        return 'review'
      } else if (this.checkPermission(['TY_DISINFECTANT_COLLECTION_CONFIRM']) && row.status === 2) {
        return 'review'
      }
      return ''
    }
  }
}
</script>

<style>
</style>
